@use '@styles/theme.module' as *;

.modal {
  @include media($from: $sm) {
    width: 650px;
    border-radius: 16px;
    overflow: hidden;
  }

  @include media($from: $lg) {
    width: 720px;
  }

  @include media($from: $xl) {
    width: 840px;
  }

  button:global(.MuiButtonBase-root) {
    z-index: 2;
  }
}
